document.addEventListener('DOMContentLoaded', () => {

    const validationErrorClass = 'validation-error'
    const parentErrorClass = 'has-validation-error'
    const inputs = document.querySelectorAll('input, select, textarea')
    inputs.forEach(function (input) {
    function checkValidity (options) {
        const insertError = options.insertError
        const parent = input.parentNode
        const error = parent.querySelector(`.${validationErrorClass}`)
        || document.createElement('div')

        if (!input.validity.valid && input.validationMessage) {
        error.className = validationErrorClass
        error.textContent = input.validationMessage

        if (insertError) {
            parent.insertBefore(error, input)
            parent.classList.add(parentErrorClass)
        }
        } else {
        parent.classList.remove(parentErrorClass)
        error.remove()
        }
    }
    input.addEventListener('input', function () {
        // We can only update the error or hide it on input.
        // Otherwise it will show when typing.
        checkValidity({insertError: false})
    })
    input.addEventListener('invalid', function (e) {
        // prevent showing the default display
        e.preventDefault()

        // We can also create the error in invalid.
        checkValidity({insertError: true})
    })
    })
});