import favouriteButton from 'javascripts/components/favouriteButton.js';

export default {
  name: 'documentResult',
  components: {
    favouriteButton
  },
  props: {
    result: Object,
    labels: Object,
    favourites: {
      type: Array,
      default: () => []
    },
    favourites_enabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    clickVideoResource() {
      this.$emit("click", this.result);
    }
  },
  template:
    `
    <article>
      <favourite-button
        v-if="favourites_enabled"
        :favourable_item="result"
        :favourites="favourites"
      ></favourite-button>
      
      <a
        v-if="!result.embed_html && !result.external_link"
        class="resource__link"
        :href="result.path"
        target="_blank"
        :download="result.name"
      >
        <h1 v-if="result.name" v-html="result.name"></h1>
        <p v-if="result.description" v-html="result.description"></p>
      </a>

      <a
        v-if="result.external_resource && !result.embed_html"
        class="resource__link resource__link--is-external"
        :href="result.external_link"
        target="_blank"
        :download="result.name"
      >
        <h1 v-if="result.name" v-html="result.name"></h1>
        <p v-if="result.description" v-html="result.description"></p>
      </a>

      <a
        :href="result.path"
        role="button"
        data-toggle="modal"
        data-target="#resource-modal"
        v-if="result.embed_html"
        class="resource__link resource__link--has-video"
        @click.prevent="clickVideoResource"
      >
        <h1 v-if="result.name" v-html="result.name"></h1>
        <p v-if="result.description"><span v-html="result.description.slice(0, 140)"></span><span v-if="result.description.length > 180">&#8230;</span></p>
      </a>
    </article>
    `,
}
