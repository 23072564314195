import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';
import {js_loaded_mixin} from 'javascripts/mixins/js_loaded_mixin.js';

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content');

  Vue.component('navbar', {
    mixins: [focus_trap_mixin, js_loaded_mixin],
  })

  new Vue({
    el: "#navbar"
  })
});
