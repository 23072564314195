import axios from 'axios';
import qs from 'qs';

export default {
    name: 'favouriteButton',
    props: {
        favourites: Array,
        favourable_item: Object,
    },
    methods: {
        toggleFavourite() {

            if (this.isFavourited) {
                this.unfavouriteItem();
            } else {
                this.favouriteItem();
            }
        },
        favouriteItem() {
            this.favourites.push(this.favourable_item.id);

            axios.post("/favourites.json", { favourite: {
                favourable_type: this.favourable_item.klass,
                favourable_id: `${this.favourable_item.id}`,
            }})
        },
        unfavouriteItem() {
            this.favourites.splice(this.favourites.indexOf(this.favourable_item.id), 1);

            axios.interceptors.request.use(config => {
                config.paramsSerializer = params => {
                    return qs.stringify(params, {
                        arrayFormat: "brackets",
                        encode: false
                    });
                };
                // window.console.log(config)

                return config;
            });

            const favourite_params = {
                favourite: {
                    favourable_type: this.favourable_item.klass,
                    favourable_id: `${this.favourable_item.id}`,
                }
            }

            axios.delete(`/favourites/${this.favourable_item.id}.json`, { params: favourite_params }).then(() => {
            })

        },
    },
    computed: {
        isFavourited() {
          return this.favourites.includes(this.favourable_item.id);
        }
    },
    template:
        `
        <button
            class="organisation-result__favourite-btn"
            :class="{ 'organisation-result__favourite-btn--active': isFavourited }"
            @click="toggleFavourite"
            aria-label="Add to Favourites"
            :aria-pressed="isFavourited"
        >
            <span v-if="!isFavourited">Add to Favourites</span>
            <span v-else>Remove from Favourites</span>
            <i class="fas fa-heart" v-if="isFavourited"></i>
            <i class="far fa-heart" v-else></i>
        </button>

        `
    ,
}
