export const scroll_to_top = {
  data() {
    return {
      isFirefox: null,
      scrollOptions: {
        top: 0,
        left: 0,
      }
    }
  },
  methods: {
    scrollToTop() {
      if (!this.isFirefox) {
        this.scrollOptions.behavior = 'smooth';
      }

      window.scrollTo(this.scrollOptions)
    },
    scrollToElement(target_id) {
      const target = document.getElementById(target_id);

      console.log(this.scrollOptions);
      this.scrollOptions.top = target.offsetTop;

      if (!this.isFirefox) {
        this.scrollOptions.behavior = 'smooth';
      }

      window.scrollTo(this.scrollOptions)
    },
  },
  created() {
    this.isFirefox = typeof InstallTrigger !== 'undefined';
  }
}
