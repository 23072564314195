import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import moment from 'moment';
import blogArticleResult from 'javascripts/components/blogArticleResult.js';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("blog-article-browser")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  const BlogArticleBrowser = Vue.component('blog-article-browser', {
    components: {
      blogArticleResult
    },
    data() {
      return {
        favourites: null,
        favourites_enabled: false,
        favourites_apply_to: [],
        blog_articles_options: null,
        is_mobile: window.innerWidth <= 992,
        show_filter: true,
        filter_status: "",
        blog_articles: [],
        date: null,
        date_string: "",
        year: null,
        year_string: "",
        pagination: {
          page: 1,
          per_page: 9,
          total_count: 0,
          total_pages: 0,
        },
        vue_loaded: false,
      };
    },
    methods: {
      toggleFilter() {
        this.show_filter = !this.show_filter;
      },
      loadData() {
        axios.get('blog_articles.json', { params: {
          page: this.pagination.page,
          per_page: this.pagination.per_page,
          date: this.date,
          year: this.year,
        }}).then((response) => {          
          this.favourites = response.data['favourites'];
          this.favourites_enabled = response.data.favourites_enabled;
          this.favourites_apply_to = response.data.favourites_apply_to;
          this.blog_articles_options = response.data.blog_articles_options;
          this.blog_articles = response.data.blog_articles;
          this.pagination = response.data.pagination;
        });
      },
      scrollToTop() {
        window.scrollBy({
          top: -window.pageYOffset,
          left: 0,
          behavior: 'smooth'
        });
      },
      gotoPage(page) {
        this.scrollToTop();
        this.pagination.page = page;
        this.loadData();
      },
      filterByMonth(date, date_string) {
        if (this.is_mobile) {
          this.toggleFilter();
        }

        this.scrollToTop();
        this.date = date;
        this.date_string = date_string;
        this.year = null;
        this.year_string = "";
        this.pagination.page = 1;
        this.loadData();
      },
      filterByYear(year, year_string) {
        this.scrollToTop();
        this.date = null;
        this.date_string = "";
        this.year = year;
        this.year_string = year_string;
        this.pagination.page = 1;
        this.loadData();
      }
    },
    computed: {
      documentBody() {
        return document.getElementById('js-body');
      },
      itemIsFavourable() {
        return this.favourites_enabled && this.favourites_apply_to.includes("BlogArticle");
      },
    },
    mounted() {
      if (this.is_mobile) {
        this.show_filter = false;
      }
    },
    created() {
      this.loadData();

      addEventListener('resize', () => {
        this.is_mobile = innerWidth <= 992;

        if (this.is_mobile) {
          this.show_filter = false;
        } else {
          this.show_filter = true;
        }
      })
    },
    filters: {
      formatDate(value) {
        if (value) {
          return moment(String(value)).format('LL');
        }
      },
    },
    })

    new Vue({
      el: "#blog-article-browser"
    })
  }
});
