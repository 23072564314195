import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import {pagination} from 'javascripts/mixins/pagination.js';
import {search_form} from 'javascripts/mixins/search_form.js';
import {search_filters} from 'javascripts/mixins/search_filters.js';
import {scroll_to_top} from 'javascripts/mixins/scroll_to_top.js';
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';
import documentResult from 'javascripts/components/documentResult.js';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("documents_index")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    const Documents = Vue.component('documents', {
      mixins: [
        scroll_to_top,
        search_form,
        search_filters,
        pagination,
        focus_trap_mixin,
      ],
      components: {
        documentResult
      },
      props: [
        'per_page'
      ],
      data: function () {
        return {
          favourites: null,
          favourites_apply_to: [],
          favourites_enabled: false,
          show_error: false,
          show_modal_description: false,
          active_video_resource: null,
          is_mobile: window.innerWidth <= 768,
          modal_visible_class: 'modal-open',
          show_filter: true,
          filter_status: "",
          search: {
            model: 'documents',
            path: 'documents',
            search_type: 'search_document',
            search_url: "/documents?page=1&per_page=10",
          },
          refreshing_filters: true,
          modal_visible_class: 'modal-open',
          is_mobile: window.innerWidth <= 768
        }
      },
      methods: {
        setVideoEmbed(resource) {
          this.active_video_resource = resource;
          this.toggleOverlay();
        },
        triggerUpdateUrl() {
          this.search.search_url = this.updateUrl();
          router.push({ path: this.search.search_url });
        },
        loadData() {
          this.search.results = [];
          this.triggerUpdateUrl();
          this.show_spinner = true;

          axios.get(`${ this.search.path }.json`, { params: this.search.url_search_params }).then((response) => {
            this.favourites = response.data['favourites'];
            this.handleResponse(response);
            this.refreshing_filters = false;
            this.favourites_enabled = response.data.favourites_enabled;
            this.favourites_apply_to = response.data.favourites_apply_to;
          });
        },
        newSearch(refresh_filters) {
          this.show_error = false;

          if (this.search.search_url != this.updateUrl()) {
            if (refresh_filters) {
              this.refreshing_filters = refresh_filters;
              this.clearCategories();
            }

            this.newSearchSubmitted();
            this.triggerUpdateUrl();
          } else {
            this.show_error = true;
          }
        },
        clearAll() {
          this.show_error = false;
          this.clearSearch();
          this.clearCatsAndScrollTop();
        },
        closeModal() {
          this.toggleOverlay();
        },
      },
      computed: {
        documentBody() {
          return document.getElementById('js-body');
        },
        itemIsFavourable() {
          return this.favourites_enabled && this.favourites_apply_to.includes("Document");
        },
        focusableItems() {
          const CLASS_NAME = '.js-modal-focusable';

          return document.querySelectorAll(`a[href]:not(${CLASS_NAME}), input:not(${CLASS_NAME}), button:not(${CLASS_NAME}), select, textarea, iframe, [tabindex], [contentEditable=true]`);
        }
      },
      mounted() {
        // Initialise local variables from set params
        this.initialiseFromParams();

        axios.get(`${ this.search.path }.json`, { params: { per_page: 1 } }).then((response) => {
          this.search.category_schemes = response.data.category_schemes;
        }).then(() => {
          // Now load full data for search
          this.loadData();
        })

        if (this.is_mobile) {
          this.show_filter = false;
        }
      },
      created() {
        this.search.path = window.location.pathname;

        if (this.is_mobile && this.search.initial_search_submitted) {
          this.documentBody.classList.add(this.modal_visible_class);
        }

        addEventListener('resize', () => {
          this.is_mobile = innerWidth <= 768;
          this.documentBody.classList.remove(this.modal_visible_class);

          if (this.is_mobile) {
            this.show_filter = false;
          } else {
            this.show_filter = true;
          }
        })
      },
      watch: {
        '$route' (to, from) {
          this.clearSearch();
          this.initialiseFromParams();

          if (to != "/documents?page=1&per_page=10") {
            this.search.search_submitted = true;
          };

          this.loadData();
        }
      }
    })

    const router = new VueRouter({
      mode: 'history',
      routes: [
        { path: '/documents/*', component: Documents }
      ]
    })

    new Vue({router}).$mount('#documents_index');
  }
});
