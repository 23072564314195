import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import VueFlatPickr from 'vue-flatpickr-component';
import {pagination} from 'javascripts/mixins/pagination.js';
import {search_form} from 'javascripts/mixins/search_form.js';
import {search_filters} from 'javascripts/mixins/search_filters.js';
import {flatpickr_config} from 'javascripts/mixins/flatpickr_config.js';
import {scroll_to_top} from 'javascripts/mixins/scroll_to_top.js';

import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatPickr, {
  name: 'flat-pickr'
});

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("site-searches-section")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    const SiteSearches = Vue.component('site_searches', {
      mixins: [scroll_to_top, flatpickr_config, search_form, search_filters, pagination],
      props: [
        'per_page'
      ],
      data: function () {
        return {
          save_search: {
            new_name: "",
            form_visible: false,
            submission_attempted: false,
            saved_searches: [],
            show_success_message: false
          },
          search: {
            model: 'results',
            path: 'site_search',
            search_type: 'search_site',
            search_url: "/site_search?page=1&per_page=10",
          },
          promoted_links: null,
          show_error: false,
          refreshing_filters: true,
          sort_options: [{"name": "Alphabetical", "parameter": null, "selected": true}],
          is_mobile: window.innerWidth <= 992,
          cancel_token: null
        };
      },
      methods: {
        triggerUpdateUrl() {
          if (this.search.term) {
            this.save_search.new_name = this.search.term;
          }

          this.search.search_url = this.updateUrl();
          router.push({ path: this.search.search_url });
        },
        loadData() {
          this.search.results = [];
          this.promoted_links = [];
          this.triggerUpdateUrl();
          this.show_spinner = true;

          if (this.search.url_search_params != null) {
            this.search.initial_search_submitted = true;
            this.search.search_submitted = true;
          }

          // if we have a cancel_token already then cancel any pending get requests
          if (this.cancel_token) {
            this.cancel_token.cancel('Operation canceled by the user.');
          }

          // otherwise, create new cancel token to send with get request
          this.cancel_token = axios.CancelToken.source();

          axios.get(`/${ this.search.path }.json`, {
            params: this.search.url_search_params,
            cancelToken: this.cancel_token.token
          }).then((response) => {
            this.handleResponse(response);
            this.refreshing_filters = false;
            this.search.results = response.data.results;
            this.promoted_links = response.data.promoted_links;
          });
        },
        newSearch(refresh_filters) {
          this.show_error = false;

          if (this.search.search_url != this.updateUrl()) {
            this.search.form_visible = !this.search.form_visible;

            if (refresh_filters) {
              this.refreshing_filters = refresh_filters;
              this.clearCategories();
            }

            if (this.show_filter) this.toggleFilter();

            if (this.save_search.form_visible) this.toggleSaveSearchForm();

            if (this.is_mobile) this.scrollToTop();

            this.save_search.show_success_message = false;
            this.save_search.form_visible = true;

            this.newSearchSubmitted();
            this.triggerUpdateUrl();
          } else {
            this.show_error = true;
          }
        },
        triggerClearSearch() {
          this.show_error = false;
          this.save_search.show_success_message = false;
          this.save_search.form_visible = true;
          this.clearSearch();
        },
        clearAll() {
          this.show_error = false;
          this.clearSearch();
          this.clearCatsAndScrollTop();
        },
        toggleSaveSearchForm() {
          this.save_search.form_visible = !this.save_search.form_visible;
        },
        updateSavedSearches() {
          axios.get('/saved_searches.json').then((response) => {
            this.save_search.saved_searches = response.data.saved_searches;
          });
        },
        loadSavedSearch(saved_search) {
          window.scrollBy({
            top: -window.pageYOffset,
            left: 0,
            behavior: 'smooth'
          });
          this.clearSearch();
          router.push({ path: saved_search.query });
          this.initialiseFromParams();
          this.search.search_submitted = true;
          this.triggerUpdateUrl();
        },
        submitSaveSearch(e) {
          e.preventDefault();

          if (this.save_search.new_name) {
            axios.post("/saved_searches.json", {
              name: this.save_search.new_name,
              query: this.search.search_url,
              controller: "site_searches"
            }).then(() => {
              this.updateSavedSearches();
              this.save_search.new_name = "";
              this.save_search.submission_attempted = false;
              this.save_search.show_success_message = true;
            });
          }
        },
        deleteSavedSearch(saved_search) {
          axios.delete("/saved_searches/" + saved_search.id + ".json").then(() => {
            this.updateSavedSearches();
          })
        }
      },
      computed: {
        documentBody() {
          return document.getElementById('js-body');
        }
      },
      created() {
        // Initialise local variables from set params
        this.initialiseFromParams();
        this.updateSavedSearches();

        // Make initial call to site_search.json to populate category schemes.
        axios.get('/site_search.json', { params: { per_page: 1 } }).then((response) => {
          this.search.category_schemes = response.data.category_schemes;
        }).then(() => {
          // Now load full data for search
          this.loadData();
          this.scrollToTop();
        })

        addEventListener('resize', () => {
          this.is_mobile = innerWidth <= 992;
        })
      },
      watch: {
        '$route' (to, from) {
          this.clearSearch();
          this.initialiseFromParams();
          if (to != "/site_search?page=1&per_page=10") {
            this.search.search_submitted = true;
          };
          this.loadData();
        }
      }
    })

    const router = new VueRouter({
      mode: 'history',
      routes: [
        { path: '/site_search/*', component: SiteSearches }
      ]
    })

    new Vue({router}).$mount('#site-searches-section');
  }
});
