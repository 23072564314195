import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();
import "stylesheets/towerhamletssendiass/application.scss";
import "javascripts/navbar";
import "javascripts/cms_pages";
import "javascripts/blog_article_browser";
import "javascripts/site_searches";
import "javascripts/was_this_useful";
import "javascripts/documents";
import "javascripts/form_validation";

import Glide, {
  Controls,
  Autoplay,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

function ready(callback) {
  // in case the document is already rendered
  if (document.readyState != "loading") callback();
  // modern browsers
  else if (document.addEventListener)
    document.addEventListener("DOMContentLoaded", callback);
  // IE <= 8
  else
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState == "complete") callback();
    });
}

ready(function () {
  if (document.getElementById("js-animated-feature-box")) {
    setTimeout(function () {
      var glide = new Glide(".glide", {
        hoverpause: false,
        perView: 1,
        autoplay: false,
      }).mount({ Controls, Autoplay, Swipe });

      // disable focus on link in unactive slides to stop user tabbing through carousel without using the appropriate actions
      glide.on(["move.after", "run"], function () {
        document;
        document
          .querySelectorAll(
            ".glide__slide:not(.glide__slide--active) .slide-wrapper a"
          )
          .forEach((link) => {
            link.setAttribute("tabindex", -1);
          });

        // Find the link in the active slide and update its tabindex
        var activeLink = document.querySelector(
          ".glide__slide.glide__slide--active .slide-wrapper a"
        );
        if (activeLink) {
          activeLink.setAttribute("tabindex", 0);
        }
      });

      var play = document.querySelector("#js_glide_play");
      var pause = document.querySelector("#js_glide_pause");

      pause.classList.add("active");

      play.addEventListener("click", function () {
        glide.settings.autoplay = 8000;
        glide.play();
        this.classList.add("active");
        pause.classList.remove("active");
      });

      pause.addEventListener("click", function () {
        glide.settings.autoplay = false;
        glide.pause();
        this.classList.add("active");
        play.classList.remove("active");
      });
    }, 1000);
  }
});
