export const focus_trap_mixin = {
  data() {
    return {
      show_overlay: false,
      mobile_only: true,
      is_mobile: window.innerWidth <= 992,
      overflow_hidden_class: "fix-body",
      sibling_element: null,
    }
  },
  methods: {
    onEscapeKeyUp(event) {
      if (event.key === 'Escape') {
        this.toggleOverlay();
      }
    },
    updateTabIndex(is_responsive) {
      let tabindex_val;

      this.$nextTick(() => {
        if (is_responsive) {
          tabindex_val = this.show_overlay && this.is_mobile ? '-1' : '0';
        } else {
          tabindex_val = this.show_overlay ? '-1' : '0';
        }

        Array.prototype.forEach.call(this.focusableItems, function (item) {
          item.setAttribute('tabindex', tabindex_val);
        });

        document.getElementById('main').setAttribute('tabindex', -1);
      });
    },
    toggleBodyClass(is_responsive) {
      if (is_responsive && this.is_mobile) {
        this.documentBody.classList.toggle(this.overflow_hidden_class);
      } else {
        this.documentBody.classList.toggle(this.overflow_hidden_class);
      }
    },
    toggleSiblingVisibility() {
      if(this.sibling_element !== null) {
        this.sibling_element.setAttribute('aria-hidden', this.show_overlay);
      }      
    },
    toggleOverlay(is_responsive) {
      this.show_overlay = !this.show_overlay;
      this.updateTabIndex(is_responsive);
      this.toggleBodyClass(is_responsive);
      this.toggleSiblingVisibility();
    }
  },
  computed: {
    documentBody() {
      return document.getElementById('js-body');
    },
    focusableItems() {
      const CLASS_NAME = '.js-is-focusable';

      return document.querySelectorAll(`a[href]:not(${CLASS_NAME}), input:not(${CLASS_NAME}), button:not(${CLASS_NAME}), select:not(${CLASS_NAME}), textarea:not(${CLASS_NAME}), iframe, [tabindex], [contentEditable=true]`);
    },
    show_mobile_menu() {
      return this.is_mobile && !this.show_overlay
    }
  },
  watch: {
    show_overlay() {
      if (this.show_overlay === false) {
        window.removeEventListener("keyup", this.onEscapeKeyUp);
      } else {
        window.addEventListener("keyup", this.onEscapeKeyUp);
      }
    }
  },
  created() {
    addEventListener('resize', () => {
      this.is_mobile = innerWidth <= 992;

      this.updateTabIndex();

      if (!this.is_mobile) {
        this.show_overlay = false;
        this.documentBody.classList.remove(this.overflow_hidden_class);
      }
    })
  }
}
