export const cms_navigation = {
    data() {
      return {
        step_back: false,
        cms_class: "",
        parent_cms_class: ""
      }
    },
    props: {
        current_page: String,
        previous_page: Object,
        pages: Array,
        root_page: Object,
        root_title: String,
        show_child_nav: Boolean,
        navigation_clicked: Boolean,
        hostname: String,
    },
    methods: {
        scrollToTop() {
            window.scrollBy({
              top: -window.pageYOffset,
              left: 0,
              behavior: 'smooth'
            });
        },
        stepBack() {
            this.resetPages(this.pages);
           
            if (this.documentCmsPage.classList.contains(this.cms_class)) {
              this.documentCmsPage.classList.remove(this.cms_class)
            }
  
            if (this.documentCmsPage.classList.contains(this.parent_cms_class)) {
              this.documentCmsPage.classList.remove(this.parent_cms_class);
            }
  
            this.$emit('update:show_child_nav', false);
  
            if (this.root_page.redirect_url != undefined && this.root_page.redirect_url != "") {
              let destination = this.root_page.redirect_url;
                if ((destination).startsWith("/pages")) {
                    this.pushRoute(destination);
                } else {
                    window.location.href = destination;
                }
            } else {
                this.pushRoute(this.root_page.link);
            }
        },
        resetPages(pages) {
          this.$emit("update:current_page", null);
          
          if (pages.expanded) pages.expanded = false;
       
          for (let page of pages) {
            page.expanded = false;
            
            if (page.children.length > 0) {
              this.resetPages(page.children);
            }
          }
        },
        visit(page) {
            this.$emit('update:navigation_clicked', true);
         
            if (this.previous_page) {
              this.resetPages(this.previous_page);
              this.previous_page = page;
            }
  
            if (this.show_child_nav) {
              this.parent_cms_class = this.cms_class;
            } else if (this.documentCmsPage.classList.contains(this.cms_class)) {
              this.documentCmsPage.classList.remove(this.cms_class);
            }
  
            this.scrollToTop();
  
            if (page.redirect_url != undefined && page.redirect_url != "") {
              let destination = page.redirect_url;

              if ((destination).startsWith("/pages")) {
                this.pushRoute(destination);
              } else if (page.redirect_url_is_external === false) {
                window.location.href = destination;
              } else {
                window.open(destination, '_blank');
              }
            } else {
                        
              this.cms_class = page.link.split("/");
              this.cms_class = this.cms_class[this.cms_class.length - 1];
              this.documentCmsPage.classList.add(this.cms_class);
  
              if (page.children.length > 0) {
                this.$emit('update:show_child_nav', true);
  
                this.focusFirstChild();
              } else {
                this.step_back = true;
              }
  
              this.$emit('update:current_page', page.title);
              
              page.expanded = true;

              this.pushRoute(page.link);
            }
        },
        updateNav(page_list, top_level) {
            let any_pages_as_expanded = false;
  
            for (let page of page_list) {
              if (this.$route.path.startsWith(page.link)) {
                if (this.show_child_nav == false ) {
                  if (page.children.length > 0) {
                    this.$emit('update:show_child_nav', true);
                  }
                }
                if (page.expanded == false && ((this.$route.path == page.link) || this.$route.path.startsWith(page.link + "/"))) {
                  page.expanded = true;
                }
  
                if (page.children.length > 0) {
                  this.updateNav(page.children, false);
                }
              } else {
                if (page.expanded == true) {
                  page.expanded = false;
                }
              }
  
              if (page.expanded == true && any_pages_as_expanded == false) {
                any_pages_as_expanded = true;
              }
            }
  
            if (this.show_child_nav == true && any_pages_as_expanded == false && top_level == true) {
              this.$emit('update:show_child_nav', false);
            }
        },
    },
    updated() {
        this.updateNav(this.pages, true);
    },
    computed: {
        documentCmsPage() {
          return document.getElementById('cms-page-content');
        }
    },
    watch: {
        '$route'(to, from) {
          this.updateNav(this.pages, true);
        }
    }
  }
  