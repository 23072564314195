import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import { cms_navigation } from 'javascripts/mixins/cms_navigation.js';
import axios from 'axios';
import qs from 'qs';
import favouriteButton from 'javascripts/components/favouriteButton.js';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("cms-page-content")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('page-breadcrumbs', {
      template: '#page_breadcrumbs',
      mixins: [
        cms_navigation
      ],
      methods: {
        pushRoute(destination) {
          router.push({ path: destination });
        },
        focusFirstChild() {
          Vue.nextTick(function () {
            const firstChildItem = document.getElementById("first_child");

            if (firstChildItem) {
              firstChildItem.focus();
            }
          })
        }
      },
      computed: {
        expandNav() {
          if (this.pages.find(page => page.expanded)) {
            return true;
          }
        },
      },
    })

    Vue.component('page-navigation', {
      template: '#page_nav',
      mixins: [
        cms_navigation
      ],
      methods: {
        pushRoute(destination) {
          router.push({ path: destination });
        },
        focusFirstChild() {
          Vue.nextTick(function () {
            const firstChildItem = document.getElementById("first_child");

            if (firstChildItem) {
              firstChildItem.focus();
            }
          })
        }
      },
      mounted() {
        this.updateNav(this.pages, true);
      }
    })

    const CmsPage = Vue.component('cms-page', {
      components: {
        favouriteButton
      },
      data() {
        return {
          favourites: null,
          favourites_enabled: false,
          favourites_apply_to: [],
          root_page: {},
          current_page: '',
          current_content: null,
          cms_documents: [],
          vue_loaded: false,
          root_title: '',
          hostname: '',
          show_child_nav: false,
          favourable_info: null,
          navigation_clicked: false,
          theme: null,
        };
      },
      methods: {
        nextUntil(elem, selector, filter) {
          // matches() polyfill
          if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
          }

          // Setup siblings array
          var siblings = [];

          // Get the next sibling element
          elem = elem.nextElementSibling;

          // As long as a sibling exists
          while (elem) {
            // If we've reached our match, bail
            if (elem.matches(selector)) break;

            // If filtering by a selector, check if the sibling matches
            if (filter && !elem.matches(filter)) {
              elem = elem.nextElementSibling;
              continue;
            }

            // Otherwise, push it to the siblings array
            siblings.push(elem);

            // Get the next sibling element
            elem = elem.nextElementSibling;
          }

          return siblings;
        },
        handleClick(e) {
          if (e.target.matches('.action-show .dropdown-button')) {
            e.preventDefault();

            const ARIA_VAL = e.target.getAttribute("aria-expanded") == 'false' ? true : false;
            e.target.setAttribute("aria-expanded", ARIA_VAL);

            document.querySelector(`[aria-labelledby='${e.target.id}']`).classList.toggle("hidden");
          } else if (e.target.matches('a')) {
            e.target.click();
          }
        },
        addCollapasibleHeaders() {
          let i = 1;

          // .action-show - this puppy stops the function running on the admin edit interface
          const CMS_HEADERS = document.querySelectorAll('.action-show .dropdown-header');

          for (let item of CMS_HEADERS) {
            if (item.innerText.trim().length > 0) {
              const REGION_ID = `region-${i}`;
              const LINK_ID = `item-${i}`;

              const NEW_LINK = document.createElement("a");
              NEW_LINK.appendChild(document.createTextNode(item.innerText));
              NEW_LINK.id = LINK_ID;
              NEW_LINK.setAttribute("aria-expanded", false);
              NEW_LINK.setAttribute("href", `#${LINK_ID}`);
              NEW_LINK.setAttribute("role", "button");
              NEW_LINK.setAttribute("aria-controls", REGION_ID);
              NEW_LINK.classList.add("dropdown-button");
              NEW_LINK.classList.add("d-block");

              item.innerText = "";

              item.parentNode.insertBefore(NEW_LINK, item);

              const NEW_REGION = document.createElement("div");
              NEW_REGION.id = REGION_ID;
              NEW_REGION.setAttribute("aria-labelledby", LINK_ID);
              NEW_REGION.setAttribute("role", 'region');
              NEW_REGION.classList.add("hidden", "header-inner");

              const REGION_ELS = this.nextUntil(item, '.dropdown-header');
              REGION_ELS.forEach(el => NEW_REGION.appendChild(el));
              item.parentNode.insertBefore(NEW_REGION, item);
              item.innerText = i;

              i++;
            }
          }

          for (let item of CMS_HEADERS) {
            item.parentNode.removeChild(item);
          }
        },
        elementToJson(e) {
          var redirect_url = e.getElementsByTagName("a")[0].dataset.redirectUrl;
          var redirect_url_is_external = false;

          if (redirect_url && !(redirect_url.startsWith('/'))) {
              try {
                redirect_url_is_external = (window.location.hostname != new URL(redirect_url).hostname)
              }
              catch (e) {
              }
          }

          var expanded = ((this.$route.path == e.getElementsByTagName("a")[0].pathname) || this.$route.path.startsWith(e.getElementsByTagName("a")[0].pathname + "/"));

          return ({
            title: e.getElementsByTagName("a")[0].textContent,
            link: e.getElementsByTagName("a")[0].pathname,
            redirect_url: redirect_url,
            redirect_url_is_external: redirect_url_is_external,
            expanded: expanded,
            children: Array.from(e.lastChild.children, this.elementToJson)
          });
        },
        loadCurrentPage() {
          let path = this.$route.path;
          this.current_content = "";

          axios.get("/fragments" + path).then((response) => {
            this.favourites = response.data['favourites'].map(item => item.id);
            this.favourites_enabled = response.data.favourites_enabled;
            this.favourites_apply_to = response.data.favourites_apply_to;
            this.vue_loaded = true;
            this.root_page.title = response.data.title;
            this.current_content = response.data.html;
            this.cms_documents = response.data.cms_documents;
            if(response.data.theme){
              this.theme =  response.data.theme
            }

            this.favourable_info = {
              id: response.data.favourable_info.id,
              klass: response.data.favourable_info.klass
            }

            this.$nextTick(() => {
              let page_images = document.querySelector('.cms__content img');
              let local_str;

              if (page_images) {
                local_str = page_images.src;

                page_images.src = local_str.replace('http:\/\/hartlepool.hubb.test', 'https:\/\/www.hartlepoolnow.co.uk');
              }

              this.addCollapasibleHeaders();
              document.title = this.root_page.title;

            });
          });
        }
      },
      computed: {
        itemIsFavourable() {
          return this.favourites_enabled && this.favourites_apply_to.includes("CmsPage");
        }
      },
      mounted() {
        if (this.$refs.page_navigation) { this.root_page = this.elementToJson(this.$refs.page_navigation.getElementsByTagName("li")[0]); }

        this.root_title = this.root_page.title;

        this.hostname = window.location.hostname;

        this.addCollapasibleHeaders();

        let hash = window.location.hash;

        if (hash) {
          document.getElementById(hash.split('#')[1]).click();
        }
      },
      created() {
        this.loadCurrentPage();
      },
      watch: {
        '$route' (to, from) {
          this.loadCurrentPage();
        },
      }
    })
    const router = new VueRouter({
      mode: 'history',
      routes: [
        { path: '/pages/*', component: CmsPage }
      ]
    })

    new Vue({router}).$mount('#cms-page-content');
  }
});
