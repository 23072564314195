export const search_filters = {
  data() {
    return {
      show_filter: false,
      modal_visible_class: 'modal-open',
      expanded_category_ids: []
    }
  },
  methods: {
    toggleFilter() {
      this.show_filter = !this.show_filter;
    },
    expandCategory(id) {
      return this.expanded_category_ids.includes(id);
    },
    clearCatsAndScrollTop() {
      this.clearCategories();
      this.newSearch(false);
      this.scrollToTop();
    },
    clearCategories() {
      for (let category_scheme of this.search.category_schemes) {
        for (let category of category_scheme.categories) {
          if (category.selected == true) {
            category.selected = false;
          }

          if (category_scheme.is_nested && category.subcategories) {
            for (let subcategory of category.subcategories) {
              if (subcategory.selected) {
                subcategory.selected = false;
              }
            }
          }
        }
      }

      this.expanded_category_ids = [];
    }
  }
}
