export const pagination = {
  data() {
    return {
      pagination: {
        page: 1,
        per_page: 10,
        total_count: 0,
      }
    }
  },
  methods: {
    nextPage() {
      this.scrollToTop();
      this.pagination.page = parseInt(this.pagination.page) + 1;
      this.triggerUpdateUrl();
    },
    previousPage() {
      this.scrollToTop();
      this.pagination.page = parseInt(this.pagination.page) - 1;
      this.triggerUpdateUrl();
    },
  }
}
