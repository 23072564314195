export const flatpickr_config = {
  data() {
    return {
      config: {
        altFormat: "j F, Y",
        altInput: true,
        minDate: "today"
      }
    }
  }
}
