import moment from 'moment';
import favouriteButton from 'javascripts/components/favouriteButton.js';

export default {
  name: 'blogArticleResult',
  components: {
      favouriteButton
  },
  props: {
    blog_article: Object,
    options: Object,
    favourites: {
      type: Array,
      default: () => []
    },
    favourites_enabled: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: null
    },
    year: {
      type: String,
      default: null
    },
    pagination_page: {
      type: Number,
      default: null
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('LL');
      }
    },
  },
  template:
  `
  <article
    class="post-item post-item--alt"
    :class="[ !date && !year && pagination_page == 1 ? 'feature-posts' : '' ]"
    >
        <favourite-button
          v-if="favourites_enabled"
          :favourable_item="blog_article"
          :favourites="favourites"
        ></favourite-button>

        <a
          :href="blog_article.path"
          class="post-item__link"
          :aria-labelledby="blog_article.id"
        >
            <template v-if="blog_article.image_url">
              <div v-if="options.fixed_ratio_image" class="post-item__img-wrapper">
                  <img :src="blog_article.image_url" :alt="blog_article.alt_tags" class="post-item__img" v-if="!blog_article.tile_bg_image">
              </div>
              <div v-else class="post-item__img-wrapper post-item__img-wrapper--bg-image" :style="{ backgroundImage: 'url(' + blog_article.image_url + ')' }">
              </div>
            </template>

            <div class="post-item__content">
                <div class="post-item__heading-wrapper">
                    <small class="post-item__published-date">
                        {{ blog_article.publish_date | formatDate }}
                    </small>

                    <h2 class="post-item__heading" :id="blog_article.id">
                        {{ blog_article.headline }}
                    </h2>
                </div>

                <div class="post-item__cta-wrapper">
                    <div class="post-item__cta">
                        <div class="post-item__cta-text">{{ options.single_button_content }}</div>
                    </div>
                </div>
            </div>
        </a>
    </article>
    `
}
