import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';
import {js_loaded_mixin} from 'javascripts/mixins/js_loaded_mixin.js';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("js-was-this-useful")) {
    window.onkeyup = function (event) {
     if (event.keyCode == 'escape') {
       document.getElementById(boxid).style.visibility="hidden";
     }
    }

    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    Vue.component('was_this_useful', {
      data() {
        return {
          selected_rating: null
        }
      },
      mixins: [focus_trap_mixin, js_loaded_mixin],
      methods: {
        selectRating(rating) {
          this.selected_rating = rating;
        },
        checkedItem(idx) {
          return idx == this.selected_rating;
        },
        closeModal() {
          this.toggleOverlay();
        },
      },
      computed: {
        focusableItems() {
          const CLASS_NAME = '.js-smiley-focusable';

          return document.querySelectorAll(`a[href]:not(${CLASS_NAME}), input:not(${CLASS_NAME}), button:not(${CLASS_NAME}), select:not(${CLASS_NAME}), textarea:not(${CLASS_NAME}),  iframe, [tabindex], [contentEditable=true]`);
        }
      },
      mounted() {
        this.sibling_element = document.getElementsByClassName('was-this-useful__row')[0];
      }
    })

    new Vue({
      el: "#js-was-this-useful"
    })
  }
});
