export const js_loaded_mixin = {
  data() {
    return {
      js_loaded: true,
    };
  },
  mounted() {
    document.getElementById('js-body').classList.add('js-loaded');
  }
}
